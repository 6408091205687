import { Controller } from "stimulus"
import flatpickr from "flatpickr"

export default class extends Controller
  initialize: ->
    config = {
      altInput: true
      altFormat: "m/d/Y"
    }
    flatpickr @element, config

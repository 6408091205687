import {Controller} from "stimulus"

export default class extends Controller
  @targets: ["source"]
  copy: ->
    dummy = document.createElement('input')
    dummy.value = @data.get('url')
    document.body.appendChild(dummy)
    dummy.select()
    if document.execCommand('copy')
      Swal.fire 'Copied'
    else
      Swal.fire 'Copy failed'
    document.body.removeChild(dummy)

import {Controller} from "stimulus"
import AxiosRequest from "../../utils/axios-request"

export default class extends Controller
  @targets = ['name',  'email', 'address', 'code', 'city', 'state']
  sweeConfig =
    showCancelButton: true,
    confirmButtonText: 'ok'
    customClass:
      confirmButton: 'uk-button green-button uk-margin-right'
      cancelButton: 'uk-button black-button'
    buttonsStyling: false
    inputAttributes:
      autocapitalize: 'on'
      autocorrect: 'on'
    allowOutsideClick: false
  connect: ->
    @uid = @.data.get 'uid'
    @holder_url = @data.get 'model_url'
    @search_url = @data.get 'search'
    @states_url = @data.get 'states'
    @models = @data.get 'models'
    @model_name = @data.get 'name'
    @id_model = @data.get 'id'
  SearchHolder: ->
    request_swal = Swal.fire {
      sweeConfig...
      title: "look up the #{@model_name}'s name",
      confirmButtonText: 'Next'
      cancelButtonText: 'Cancel'
      html: "<input type=\"text\" name=\"holder\" id=\"holder_id\" class=\"uk-input swal2-input\" placeholder=\"Enter name of #{@model_name}\">"
    }
    request_swal.then ({value}) =>
      if value?
        uid = @uid
        holder_search = document.getElementById('holder_id').value
        AxiosRequest("#{@holder_url}", {holder_search}, 'post').then ({data}) =>
          if data.enabled == true
            if @model_name == 'Holder'
              option_holder = data.holders.map((element) => ("<option value=\"#{element.idholder}\">#{element.name}</option>"))
            else if @model_name == 'Producer'
              option_holder = data.producers.map((element) => ("<option value=\"#{element.idproducer}\">#{element.name}</option>"))
            else if @model_name == 'Insured'
              option_holder = data.insureds.map((element) => ("<option value=\"#{element.idinsured}\">#{element.name}</option>"))
            req_swal = Swal.fire {
              sweeConfig...
              title: "Select an #{@model_name}",
              confirmButtonText: 'Next'
              cancelButtonText: 'Cancel'
              width: '100%'
              html: "<select id='op_holder' class='swal2-input'>"+option_holder+"</select>"
            }
            req_swal.then ({value}) =>
              if value?
                holder_select = document.getElementById('op_holder').value
                $.get("#{@search_url}", {holder_select}).done (data_select) =>
                  document.getElementById("#{@id_model}").value = data_select.id
                  @nameTarget.textContent = data_select.name
                  @emailTarget.textContent = data_select.email
                  @addressTarget.textContent = data_select.address
                  @cityTarget.textContent = data_select.city
                  @stateTarget.textContent = data_select.state
                  @codeTarget.textContent = data_select.code
          else
            # si no hay ningun resultado en la busqueda
            $.get("#{@states_url}").done (data_states) =>
              option_states = data_states.states.map((element) => ("<option value=\"#{element.idstate}\">#{element.state}</option>"))
              if @model_name == 'Producer'
                swal = Swal.fire {
                  sweeConfig...
                  title: "Register #{@model_name}",
                  confirmButtonText: 'Register'
                  cancelButtonText: 'Cancel'
                  html: '
                  <label for="name" class="uk-input-label uk-text-bolder green-text">Name</label>
                  <input type="text" name="name" id="name_holder" class="uk-input swal2-input" placeholder="Name">
                  <label for="address" class="uk-input-label uk-text-bolder green-text">Address</label>
                  <input type="text" name="address" id="address_holder" class="uk-input swal2-input" placeholder="Address">
                  <label for="zip_code" class="uk-input-label uk-text-bolder green-text">Zip Code</label>
                  <input type="text" name="zip_code" id="zip_code_holder" class="uk-input swal2-input" placeholder="Zip Code">'+
                  "<div data-controller=\"master-detail\" data-master-detail-victim=\"#cities_p\" data-master-detail-url=\"/cities\">"+
                    '<label for="state" class="uk-input-label uk-text-bolder green-text">Select state</label>'+
                    "<select id='state' class='swal2-input' data-target='master-detail.master' data-action='master-detail#setVictims'>
                      <option value=''>Select an State</option>"+option_states+
                    "</select>
                  </div>"+
                  '<label for="cities" class="uk-input-label uk-text-bolder green-text">Select an city</label>'+
                  "<select id='cities_p' class='swal2-input'>
                    <option value=''>Select an city</option>
                  </select>"
                }
              else
                swal = Swal.fire {
                  sweeConfig...
                  title: "Register #{@model_name}",
                  confirmButtonText: 'Register'
                  cancelButtonText: 'Cancel'
                  html: '
                  <label for="name" class="uk-input-label uk-text-bolder green-text">Name</label>
                  <input type="text" name="name" id="name_holder" class="uk-input swal2-input" placeholder="Name">
                  <label for="email" class="uk-input-label uk-text-bolder green-text">Email</label>
                  <input type="email" name="email" id="email_holder" class="uk-input swal2-input" placeholder="Email">
                  <label for="address" class="uk-input-label uk-text-bolder green-text">Address</label>
                  <input type="text" name="address" id="address_holder" class="uk-input swal2-input" placeholder="Address">
                  <label for="zip_code" class="uk-input-label uk-text-bolder green-text">Zip Code</label>
                  <input type="text" name="zip_code" id="zip_code_holder" class="uk-input swal2-input" placeholder="Zip Code">'+
                  "<div data-controller=\"master-detail\" data-master-detail-victim=\"#cities_p\" data-master-detail-url=\"/cities\">"+
                    '<label for="state" class="uk-input-label uk-text-bolder green-text">Select state</label>'+
                    "<select id='state' class='swal2-input' data-target='master-detail.master' data-action='master-detail#setVictims'>
                      <option value=''>Select an State</option>"+option_states+
                    "</select>
                  </div>"+
                  '<label for="cities" class="uk-input-label uk-text-bolder green-text">Select an city</label>'+
                  "<select id='cities_p' class='swal2-input'>
                    <option value=''>Select an city</option>
                  </select>"
                }
              swal.then ({value}) =>
                if value?
                  name = document.getElementById('name_holder').value
                  address = document.getElementById('address_holder').value
                  zip_code = document.getElementById('zip_code_holder').value
                  state = document.getElementById('state').value
                  cities = document.getElementById('cities_p').value
                  email = document.getElementById('email_holder').value if @model_name == 'Insured' || @model_name == 'Holder'
                  authenticity_token = $('[name="csrf-token"]')[0].content
                  if @model_name == 'Producer'
                    if name == '' || address == '' || zip_code == '' || state == '' || cities == ''
                      Swal.fire({
                        icon: 'error'
                        title: 'Oops...error fill in data'
                      })
                    else
                      producer = {
                        name: name,
                        address_attributes:
                          address: address
                          zip_code: zip_code
                          state_id: parseInt(state)
                          city_id: parseInt(cities)
                          country_id: 229
                      }
                  else if @model_name == 'Insured'
                    if name == '' || address == '' || zip_code == '' || state == '' || cities == '' || email == ''
                      Swal.fire({
                        icon: 'error'
                        title: 'Oops...error fill in data'
                      })
                    else
                      insured = {
                        name: name,
                        email: email
                        address_attributes:
                          address: address
                          zip_code: zip_code
                          state_id: parseInt(state)
                          city_id: parseInt(cities)
                          country_id: 229
                      }
                  else if @model_name == 'Holder'
                    if name == ''
                      Swal.fire({
                        icon: 'error'
                        title: 'Oops...error fill in data'
                      })
                    else
                      holder = {
                        name: name,
                        email: email
                        address_attributes:
                          address: address
                          zip_code: zip_code
                          state_id: parseInt(state)
                          city_id: parseInt(cities)
                          country_id: 229
                      }
                  if producer || insured || holder
                    AxiosRequest("#{@models}", {authenticity_token, producer, insured, holder}, 'post').then ({data}) =>
                      document.getElementById("#{@id_model}").value = data.id
                      @nameTarget.textContent = data.name
                      @emailTarget.textContent = data.email
                      @addressTarget.textContent = data.address
                      @cityTarget.textContent = data.city
                      @stateTarget.textContent = data.state
                      @codeTarget.textContent = data.code
                      Swal.fire({
                        icon: 'success'
                        title: "Successful #{@model_name} register"
                      })
